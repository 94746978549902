import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useRef, useState } from "react";
import { InfoCircle, Upload } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider";
import * as Messages from "../messages/messages";
import {
  useConfirmFileUpload,
  useGetPresignedUrl,
  useUploadToS3,
} from "../Slides/Hooks/fileQueries";
import "./styles/file-field.css";

const CircularProgressWithLabel = (props) => {
  return (
    <Box
      id="file-loader-container"
      sx={{ position: "relative", display: "inline-flex" }}
    >
      <CircularProgress
        className="file-loader"
        id="file-loader"
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          className="file-percentage"
          id="file-percentage"
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

// FileField component is used to render a file input field in the form
const FileField = ({
  idProp,
  state,
  setState,
  disabledInput,
  isUploading,
  setIsUploading,
}) => {
  const inputFileRef = useRef(null);
  const inputContainerRef = useRef(null);
  const [displayName, setDisplayName] = useState("");
  const [labelValue, setLabelValue] = useState("Upload");
  const { setIsLoading, handleInputDisable, disableAll } =
    useContext(FormContext);
  const { userId, clientId, projectId } = useContext(DashboardContext);

  const [progress, setProgress] = useState(0);

  const getPresignedUrl = useGetPresignedUrl(setIsUploading);
  const uploadToS3 = useUploadToS3(setIsUploading);
  const confirmFileUpload = useConfirmFileUpload(setIsLoading, setIsUploading);

  // Function to handle opening the attach file dialog
  const handleButtonClick = (e) => {
    e.preventDefault();
    inputFileRef.current.click();
  };

  const handleAttachFile = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const fileType = file.type;

    try {
      setIsUploading(true);
      const presignedUrl = await getPresignedUrl.mutateAsync({
        clientId,
        projectId,
        idProp,
        fileName,
        fileType,
      });

      const { presignedPost, filePath } = presignedUrl.data;

      await uploadToS3.mutateAsync({ presignedPost, file, setProgress });

      const confirmS3 = await confirmFileUpload.mutateAsync({
        clientId,
        projectId,
        idProp,
        filePath,
        fileName,
      });

      const formData = new FormData();

      formData.append("file", file);

      formData.append("fileName", fileName);

      setState((prevState) => ({
        ...prevState,
        [idProp]: { ...prevState[idProp], file: confirmS3.data.file },
      }));

      setDisplayName(file.name);

      setProgress(0);
    } catch (err) {
      console.log("ERROR UPLOADING FILE: ", err);
    }
  };

  // Function to open the file in a new tab if one exists
  const openFileInNewTab = () => {
    const file = state[idProp].file;
    console.log("URL: ", typeof file);
    if (file && typeof file === "string") {
      //open url in new tab
      window.open(file, "_blank");
    } else if (file && file instanceof File) {
      // Open file object in new tab
      const url = URL.createObjectURL(file);

      //open url in new tab
      window.open(url, "_blank");
    }
  };

  // Update the display name of the file input
  useEffect(() => {
    if (state[idProp].file?.name) {
      setDisplayName(state[idProp].file.name);
      setLabelValue("Change");
    } else if (typeof state[idProp].file === "string") {
      let str = state[idProp].file.split("/");
      setDisplayName(str[str.length - 1]);
      setLabelValue("Change");
    } else {
      setDisplayName("");
      setLabelValue("Upload");
    }
  }, [state[idProp]]);

  // Add class to input container if a file is attached
  useEffect(() => {
    displayName
      ? inputContainerRef.current.classList.add("name-exists")
      : inputContainerRef.current.classList.remove("name-exists");
  }, [displayName]);

  return (
    <div className="file-input-container">
      <label
        htmlFor={`${idProp}-file-input`}
        id={`${idProp}-label`}
        className="file-label-main"
      >
        {`${labelValue} File:`}
      </label>
      <div
        className="file-input"
        id={`${idProp}-file-input`}
        ref={inputContainerRef}
      >
        <Button
          className={
            !displayName ? "file-upload-btn" : "file-upload-btn uploaded-file"
          }
          id={"file-upload-btn"}
          onClick={isUploading || disableAll ? () => {} : handleButtonClick}
          disabled={
            isUploading ||
            disableAll ||
            handleInputDisable(idProp, state, disabledInput, userId)
          }
          style={{
            cursor: disableAll ? "not-allowed" : "pointer",
          }}
        >
          <Upload id={"upload-icon"} size={22} />
          {state[idProp].file ? "Replace File" : "Choose a File"}
        </Button>
        <input
          type={"file"}
          name={`${idProp}-file`}
          id={`${idProp}-file`}
          className={`${idProp}-upload-input file-upload-input`}
          onChange={isUploading || disableAll ? () => {} : handleAttachFile}
          ref={inputFileRef}
          disabled={
            isUploading ||
            disableAll ||
            handleInputDisable(idProp, state, disabledInput, userId)
          }
          style={{
            pointerEvents: disableAll ? "none" : "auto",
            cursor: disableAll ? "not-allowed" : "pointer",
          }}
        />
        {displayName ? (
          <Tooltip
            id={`${idProp}-tooltip-file-name`}
            title={displayName}
            placement="top"
            arrow
            classes={{
              tooltip: "text-field-tooltip",
              popper: "text-field-popper",
              arrow: "text-field-arrow",
              tooltipPlacementLeft: "text-field-right",
            }}
          >
            <p id="file-name-p" onClick={openFileInNewTab}>
              {displayName}
            </p>
          </Tooltip>
        ) : progress > 0 && progress < 100 ? (
          <CircularProgressWithLabel value={progress} />
        ) : null}
        <Tooltip
          id="tf-tooltip"
          title={Messages[`${idProp}Message`]}
          placement="right"
          arrow
          classes={{
            tooltip: "text-field-tooltip",
            popper: "text-field-popper",
            arrow: "text-field-arrow",
            tooltipPlacementLeft: "text-field-right",
          }}
        >
          <InfoCircle
            className="upload-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default FileField;
