import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import * as Messages from "../messages/messages";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import "../../../../Tooltip/styles/tooltip.css";
import { FormContext } from "../context/FormProvider";
import "../styles/form-container.css";
import "./styles/radio-group-field.css";

// RadioGroupField component is used to render a radio group in the form (Yes/No)
const RadioGroupField = ({
  label,
  idProp,
  state,
  setState,
  slide2,
  setSlide2,
  slide8,
  setSlide8,
  debouncedSave,
  setIsLoading,
  disabledInput,
}) => {
  const [open, setOpen] = useState(false);
  const { slide1, handleInputDisable } = useContext(FormContext);

  const { userId } = useContext(DashboardContext);

  // Function to handle the radio group change
  const handleRadioChange = (e) => {
    let newState = {};
    let realID = e.target.id.split("-")[0];
    if (e.target.value === "false") {
      if (realID === "onNativeLand") {
        setSlide2({
          ...slide2,
          submittingToRegAgency: false,
          regAgencySubmitDate: "",
        });
        newState = {
          ...state,
          [realID]: false,
        };
      } else if (realID === "submittingToRegAgency") {
        newState = {
          ...state,
          [realID]: false,
          regAgencySubmitDate: "",
        };
        setState(newState);
      } else if (realID === "activeCDX") {
        newState = {
          ...state,
          [realID]: false,
          cdxAccountManager: "",
          cdxUserId: "",
        };
        setState(newState);
      } else if (realID === "inspectionRequest") {
        newState = {
          ...state,
          [realID]: false,
          inspectingCompany: "",
          inspectorName: "",
          inspectorPhone: "",
          inspectorEmail: "",
          inspectionRecipient1: "",
          inspectionRecipient1Title: "",
          inspectionRecipient1Email: "",
          inspectionRecipient2: "",
          inspectionRecipient2Title: "",
          inspectionRecipient2Email: "",
          inspectionRecipient3: "",
          inspectionRecipient3Title: "",
          inspectionRecipient3Email: "",
          inspectionRecipient4: "",
          inspectionRecipient4Title: "",
          inspectionRecipient4Email: "",
        };
        setState(newState);
      } else if (realID === "signRequest") {
        newState = {
          ...state,
          [realID]: false,
          sign3x3: false,
          signStand: false,
          signBanner: false,
        };
        setState(newState);
      } else if (realID === "manualLogsRequest") {
        newState = {
          ...state,
          [realID]: false,
          dcc: "",
          dccPhone: "",
          dccEmail: "",
          dccTitle: "",
        };
        setState(newState);
      } else if (realID === "dustAuditRequest") {
        newState = {
          ...state,
          [realID]: false,
          dustAuditContact: "",
          dustAuditContactPhone: "",
          dustAuditContactEmail: "",
        };
        setState(newState);
      } else if (realID === "dustSignRequest") {
        newState = {
          ...state,
          [realID]: false,
          dcName: "",
          dcPhone: "",
          dustPermitNumber: "",
        };
        setState(newState);
      } else if (realID === "isLinear") {
        newState = {
          ...state,
          [realID]: false,
          eLat: "",
          eLng: "",
        };
        setState(newState);
      } else if (realID === "layDownYard") {
        newState = { ...state, [realID]: false, layDownYardDist: false };
        setState(newState);
      } else if (realID === "portableTanksOnsite") {
        newState = { ...state, [realID]: false, portableTankCapacity: "" };
      } else if (realID === "ownerGCCheck") {
        setSlide8({
          ...slide8,
          gcName: "",
          gcAdd: "",
          gcCity: "",
          gcZip: "",
          gcPhone: "",
          gcContactName: "",
          gcContactEmail: "",
          gcContactPhone: "",
          gcContactTitle: "",
        });
        newState = { ...state, [realID]: false };
        setState(newState);
      } else if (realID === "delegatedRepCheck") {
        newState = {
          ...state,
          [realID]: false,
          odrCompany: "",
          odrName: "",
          odrPhone: "",
          odrEmail: "",
          odrTitle: "",
        };
        setState(newState);
      } else {
        newState = { ...state, [realID]: false };
        setState(newState);
      }
    } else {
      if (realID === "ownerGCCheck") {
        setSlide8({
          ...slide8,
          gcName: state.oName,
          gcAdd: state.oAdd,
          gcAdd2: state.oAdd2,
          gcCity: state.oCity,
          gcZip: state.oZip,
          gcPhone: state.oPhone,
          gcContactName: state.oContactName,
          gcContactEmail: state.oContactEmail,
          gcContactPhone: state.oContactPhone,
          gcContactTitle: state.oContactTitle,
        });
        newState = { ...state, [realID]: true };
        setState(newState);
      } else {
        newState = { ...state, [realID]: true };
        setState(newState);
      }
    }
    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
  };

  return (
    <>
      <div
        className="input-radio-group-container"
        id={`${idProp}-radio-container`}
      >
        <label
          id={`${idProp}-label`}
          className="radio-group-form-label"
          htmlFor={`radio-${idProp}`}
        >
          {label}:{" "}
        </label>
        <div
          className="input-info-radio-group-container"
          id={`${idProp}-info-container`}
        >
          <RadioGroup
            row={true}
            className="radio-grouping"
            aria-label={`${label} radio group`}
            name={`radio-${idProp}`}
            id={`radio-${idProp}`}
            value={state[idProp]}
            onChange={handleRadioChange}
            classes={{
              row: "radio-group-row",
            }}
          >
            <FormControlLabel
              control={
                <Radio
                  id={`${idProp}-true`}
                  value={true}
                  checked={state[idProp] === true}
                  disabled={
                    handleInputDisable(idProp, state, disabledInput, userId) ||
                    (idProp === "submittingToRegAgency" && slide1.onNativeLand)
                  }
                  classes={{
                    root: "radio-group-btn-true",
                    checked: "radio-group-btn-checked",
                  }}
                  icon={
                    <SvgIcon
                      component={CheckBoxOutlineBlankIcon}
                      className="radio-group-btn-icon-true"
                      viewBox="3.6 3.6 16.8 16.8"
                    />
                  }
                  checkedIcon={
                    <SvgIcon
                      component={CheckBoxIcon}
                      className="radio-group-btn-icon-true"
                      viewBox="3.6 3.6 16.8 16.8"
                    />
                  }
                />
              }
              label="YES"
              id={`true-label-group`}
              classes={{
                label: "radio-group-btn-label",
                root: "radio-group-form-control-label",
              }}
            />
            <FormControlLabel
              control={
                <Radio
                  id={`${idProp}-false`}
                  value={false}
                  checked={state[idProp] === false}
                  disabled={
                    handleInputDisable(idProp, state, disabledInput, userId) ||
                    (idProp === "submittingToRegAgency" && slide1.onNativeLand)
                  }
                  classes={{
                    root: "radio-group-btn-false",
                    checked: "radio-group-btn-checked",
                  }}
                  icon={
                    <SvgIcon
                      component={CheckBoxOutlineBlankIcon}
                      className="radio-group-btn-icon-false"
                      viewBox="3 3 18 18"
                    />
                  }
                  checkedIcon={
                    <SvgIcon
                      component={CloseIcon}
                      className="radio-group-btn-icon-false"
                      viewBox="2 2 20 20"
                    />
                  }
                />
              }
              label="NO"
              id={`false-label-group`}
              classes={{
                label: "radio-group-btn-label",
                root: "radio-group-form-control-label",
              }}
            />
          </RadioGroup>
          <Tooltip
            id="rg-tooltip"
            classes={{
              tooltip: "radio-group-tooltip",
              popper: "radio-group-popper",
              arrow: "radio-group-arrow",
              tooltipPlacementRight: "radio-group-right",
            }}
            title={Messages[`${idProp}Message`] || ""}
            open={open}
            arrow
            placement="right"
          >
            <InfoCircle
              className="radio-group-info-circ"
              id={`info-${idProp}`}
              color="white"
              size={30}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default RadioGroupField;
