import { useMap } from "@vis.gl/react-google-maps";
import { memo, useEffect } from "react";

// MapHandler component for the map handler
const MapHandler = ({ id, place, setSlide6 }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
};

export default memo(MapHandler);
