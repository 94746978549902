import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Fetches the current project from the database
export const fetchCurrentProject = async (clientId, projectId) => {
  const currentProject = await axios.get(
    `/api/saved-form/${clientId}/${projectId}/`,
    {
      withCredentials: true,
    },
  );

  return currentProject.data;
};

// Custom hook to fetch the current project (contains the state of every slide)
export default function useCurrentProject(clientId, projectId) {
  return useQuery({
    queryKey: ["currentProject", clientId, projectId],
    queryFn: () => fetchCurrentProject(clientId, projectId),
    enabled: !!clientId && !!projectId,
  });
}
