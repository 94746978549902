import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import Marker from "./Marker";
import {
  AdvancedMarker,
  APIProvider,
  ControlPosition,
  Map,
  Pin,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import CustomMapControl from "./MapControl";
import MapHandler from "./MapHandler";
import "./styles/map.css";

// Google Maps API key
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// CustomMapControl component for the map control
const MarkerAddressHandler = ({
  slide6,
  setSlide6,
  position,
  debouncedSave,
  setIsLoading,
}) => {
  const geocodeLib = useMapsLibrary("geocoding");
  const geocoder = useMemo(
    () => geocodeLib && new geocodeLib.Geocoder(),
    [geocodeLib],
  );

  const lastPositionRef = useRef(null);

  useEffect(() => {
    if (!geocoder || !position.lat || !position.lng) return;

    const currLat = Number(position.lat);
    const currLng = Number(position.lng);

    const hasPositionChanged =
      !lastPositionRef.current ||
      lastPositionRef.current.lat !== currLat ||
      lastPositionRef.current.lng !== currLng;

    if (hasPositionChanged) {
      lastPositionRef.current = { lat: currLat, lng: currLng };
      geocoder.geocode(
        {
          location: { lat: position.lat, lng: position.lng },
          fulfillOnZeroResults: true,
          region: "us",
        },
        (results, status) => {
          if (status === "OK") {
            let city = "";
            let county = "";
            let address = "";
            let zip = "";

            let dataArr = results;

            for (let i = 0; i < dataArr.length; i++) {
              if (city && county && address && zip) break;

              const data = dataArr[i];

              if (
                (data.geometry.location_type === "ROOFTOP" ||
                  data.geometry.location_type === "GEOMETRIC_CENTER") &&
                data.formatted_address.split(", ").length === 4
              ) {
                address =
                  data.formatted_address.split(", ")[0] !== "Unnamed Road"
                    ? data.formatted_address.split(", ")[0]
                    : "";
                city = data.formatted_address.split(", ")[1];
                zip = data.formatted_address.split(", ")[2].split("AZ ")[1];
              }

              for (let j = 0; j < data.address_components.length; j++) {
                if (city && county && address && zip) break;

                let arr2 = data.address_components[j];

                if (arr2.types.includes("point_of_interest")) {
                  address = arr2.short_name;
                  continue;
                }

                if (arr2.types.includes("locality") && !city) {
                  city = arr2.short_name;
                  continue;
                }

                if (
                  arr2.types.includes("administrative_area_level_2") &&
                  !county
                ) {
                  county = arr2.short_name.split(" County")[0];
                  continue;
                }

                if (arr2.types.includes("postal_code") && !zip) {
                  zip = arr2.short_name;
                  continue;
                }
              }
            }

            if (address.includes(" FS ")) {
              address = address.replace(" FS ", " & ");
            }

            if (address.includes(" Av ")) {
              address = address.replace(" Av ", " Ave ");
            }

            let newState = {
              ...slide6,
              pAdd: address,
              pCity: city,
              pCounty: county,
              pZip: zip,
            };

            setSlide6(newState);

            if (debouncedSave) {
              setIsLoading(true);
              debouncedSave.mutate(newState);
            }
          }
        },
      );
    }
  }, [position]);

  return null;
};

// MapComponent component for the map
const MapComponent = ({
  projectName,
  slide6,
  setSlide6,
  requestStatus,
  disableAll,
  debouncedSave,
  setIsLoading,
}) => {
  const { projectId } = useContext(DashboardContext);

  const [selectedPlace, setSelectedPlace] = useState(null);

  // Handles the map click event
  const handleMapClick = useCallback((e) => {
    let latStr = e.detail.latLng.lat.toString().slice(0, 11);
    let lngStr = e.detail.latLng.lng.toString().slice(0, 12);
    if (!slide6.isLinear) {
      // Should geocode for the address after marker.position is set
      setSlide6({
        ...slide6,
        bLat: latStr,
        bLng: lngStr,
        marker: {
          position: [e.detail.latLng.lat, e.detail.latLng.lng],
          details: ["", "", "", ""],
        },
      });
    }

    if (slide6.isLinear && !slide6.bLat && !slide6.bLng) {
      // Should geocode for the address after marker.position is set
      setSlide6({
        ...slide6,
        bLat: latStr,
        bLng: lngStr,
        marker: {
          position: [e.detail.latLng.lat, e.detail.latLng.lng],
          details: ["", "", ""],
        },
      });
    } else if (slide6.isLinear && slide6.bLat && slide6.bLng) {
      // Should NOT geocode for the address after marker2.position is set
      let newState = {
        ...slide6,
        eLat: latStr,
        eLng: lngStr,
        marker2: {
          position: [e.detail.latLng.lat, e.detail.latLng.lng],
          details: ["", "", "", ""],
        },
      };
      setSlide6(newState);

      if (debouncedSave) {
        setIsLoading(true);
        debouncedSave.mutate(newState);
      }
    }
  });

  return (
    <div
      className={`map-container`}
      style={{
        pointerEvents:
          (requestStatus !== "I" && requestStatus !== "IP") || disableAll
            ? "none"
            : "auto",
      }}
    >
      <APIProvider
        apiKey={API_KEY}
        region="us"
        language="en"
        libraries={["places", "geocoding", "geometry", "routes"]}
      >
        <Map
          defaultZoom={11}
          defaultCenter={{ lat: 33.446498, lng: -112.07239 }}
          reuseMap={true}
          mapId={`${projectId}-map`}
          mapTypeControl={false}
          mapTypeId="hybrid"
          streetViewControl={false}
          onClick={handleMapClick}
          rotateControl={false}
          restriction={{
            latLngBounds: {
              north: 36.987903541693086,
              south: 31.337062,
              west: -114.398277,
              east: -109.060888,
            },
          }}
          className="new-map"
        >
          <CustomMapControl
            slide6={slide6}
            controlPosition={ControlPosition.LEFT_TOP}
            onPlaceSelect={setSelectedPlace}
          />
          <MapHandler place={selectedPlace} setSlide6={setSlide6} />
          <AdvancedMarker
            position={{
              lat: slide6.marker.position[0] ? +slide6.marker.position[0] : 0,
              lng: slide6.marker.position[1] ? +slide6.marker.position[1] : 0,
            }}
            onClick={(e) => {
              let newState = {
                ...slide6,
                bLat: "",
                bLng: "",
                eLat: "",
                eLng: "",
                marker: {
                  position: ["", ""],
                  details: ["", "", "", ""],
                },
                marker2: {
                  position: ["", ""],
                  details: ["", "", "", ""],
                },
              };
              setSlide6(newState);

              if (debouncedSave) {
                setIsLoading(true);
                debouncedSave.mutate(newState);
              }
            }}
            title={slide6.isLinear ? "Start" : projectName}
          >
            <Pin
              background="#00e30f"
              borderColor={"#08460f"}
              glyphColor={"#0a8316"}
            />
          </AdvancedMarker>
          <AdvancedMarker
            position={{
              lat: slide6.marker2.position[0] ? +slide6.marker2.position[0] : 0,
              lng: slide6.marker2.position[1] ? +slide6.marker2.position[1] : 0,
            }}
            onClick={(e) => {
              let newState = {
                ...slide6,
                eLat: "",
                eLng: "",
                marker2: {
                  position: ["", ""],
                  details: ["", "", "", ""],
                },
              };
              setSlide6(newState);

              if (debouncedSave) {
                setIsLoading(true);
                debouncedSave.mutate(newState);
              }
            }}
            title="End"
          >
            <Pin
              background="#ff0000"
              borderColor={"#000000"}
              glyphColor={"#a30000"}
            />
          </AdvancedMarker>
          {slide6.marker.position[0] && slide6.marker.position[1] && (
            <MarkerAddressHandler
              slide6={slide6}
              setSlide6={setSlide6}
              position={{
                lat: slide6.marker.position[0],
                lng: slide6.marker.position[1],
              }}
              debouncedSave={debouncedSave}
              setIsLoading={setIsLoading}
            />
          )}
        </Map>
      </APIProvider>
    </div>
  );
};

export default MapComponent;
