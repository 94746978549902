import React, { useEffect, useRef, useState } from "react";
// import Marker from "./Marker";
import { Search } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import "./styles/map.css";

// Autocomplete component for Google Maps
const AutoCompletePlaces = ({ onPlaceSelect, slide6 }) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <TextField
        className="autocomplete-textfield"
        label="Search Google Maps"
        fullWidth={true}
        inputRef={inputRef}
        InputProps={{
          id: "autocomplete-input",
          placeholder: "",
          endAdornment: (
            <InputAdornment position="end">
              <Search id="search-icon" />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          className: "autocomplete-input-label",
          id: "autocomplete-input-label",
          classes: {
            root: "autocomplete-input-label",
            shrink: "autocomplete-input-label-shrink",
          },
        }}
        classes={{
          root: "autocomplete-textfield-root",
        }}
      />
    </div>
  );
};

export default AutoCompletePlaces;
