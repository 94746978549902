import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import { useIsMutating } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider.jsx";
import * as Messages from "../messages/messages.js";
import "./styles/attach-file-field.css";

// Component that renders a radio group with three options: Attach, Not Available, and N/A
const AttachFileField = ({
  label,
  idProp,
  state,
  setState,
  val,
  debouncedSave,
  disabledInput,
  required,
}) => {
  const [open, setOpen] = useState(false);
  // Checkboxes checked values
  const isAttached = val === "Attached";
  const isNotAvailable =
    val === "Not Available" || val === "Not Available - N/A";
  const isNotApplicable = val === "N/A" || val === "Not Available - N/A";

  const isMutating = useIsMutating();

  const { setIsLoading, handleInputDisable, disableAll } =
    useContext(FormContext);

  const { userId } = useContext(DashboardContext);

  // Function to handle the radio group change
  const handleRadioChange = (e) => {
    const newStatus = e.target.value;
    const currentStatus = state[idProp].fileStatus;
    const statusChoices = {
      Attached: "AT",
      "Not Available": "NAV",
      "N/A": "NA",
      Unconfirmed: "UC",
    };
    let newState;

    console.log("NEW STATUS", newStatus);

    if (currentStatus !== newStatus) {
      if (currentStatus === "Attached") {
        newState = {
          ...state,
          [idProp]: { ...state[idProp], file: "", fileStatus: newStatus },
        };
        setState(newState);
      } else {
        newState = {
          ...state,
          [idProp]: { ...state[idProp], fileStatus: newStatus },
        };

        if (idProp === "dustPermit") {
          newState = {
            ...newState,
            dustPermitNumber: "",
          };
        }
        setState(newState);
      }
    } else {
      newState = {
        ...state,
        [idProp]: { ...state[idProp], file: "", fileStatus: "Unconfirmed" },
      };
      setState(newState);
    }
    setIsLoading(true);

    const uploadState = [];

    for (const key in newState) {
      if (key === "dustPermitNumber") {
        continue;
      }
      console.log("key", key);
      let sendValue = {
        name: newState[key].name,
        fileStatus: statusChoices[newState[key].fileStatus],
      };

      if (key === "dustPermit") {
        sendValue["dustPermitNumber"] = newState["dustPermitNumber"];
      }

      uploadState.push(sendValue);
    }

    debouncedSave.mutate(uploadState);
  };

  return (
    <div id="input-attach-container">
      <label
        className="attach-form-file-label"
        id={`${idProp}-attach-label`}
        htmlFor="attach-file-radio-group"
      >
        {label}:
        {required && <span className="attach-required">{"(required)"}</span>}
      </label>
      <RadioGroup
        aria-label="attach-file-radio-group"
        defaultValue="Unconfirmed"
        name="radio-buttons-group"
        id="attach-file-radio-group"
        classes={{
          root: "attach-file-radio-group-root",
          row: "attach-file-radio-group-row",
        }}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="Attached"
          id="attach-form-control"
          label="Attach"
          control={
            <Radio
              classes={{
                root: "attached-radio-root",
                checked: "attached-radio-checked",
              }}
              checked={isAttached}
              disabled={
                disableAll ||
                handleInputDisable(idProp, state, disabledInput, userId) ||
                isMutating > 0
              }
              icon={
                <SvgIcon
                  component={CheckBoxOutlineBlank}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
              checkedIcon={
                <SvgIcon
                  component={CheckBox}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
            />
          }
          classes={{
            root: "attach-form-control-root",
            label: "attach-btn-file-label",
          }}
          labelPlacement="end"
        />
        <FormControlLabel
          value="Not Available"
          id="attach-form-control"
          label="Not Available"
          control={
            <Radio
              classes={{
                root: "not-available-radio-root",
                checked: "not-available-radio-checked",
              }}
              checked={isNotAvailable}
              disabled={
                disableAll ||
                handleInputDisable(idProp, state, disabledInput, userId)
              }
              icon={
                <SvgIcon
                  component={CheckBoxOutlineBlank}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
              checkedIcon={
                <SvgIcon
                  component={CheckBox}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
            />
          }
          classes={{
            root: "attach-form-control-root",
            label: "attach-btn-file-label",
          }}
          labelPlacement="end"
        />
        <FormControlLabel
          value="N/A"
          id="attach-form-control"
          label="N/A"
          control={
            <Radio
              classes={{
                root: "not-applicable-radio-root",
                checked: "not-applicable-radio-checked",
              }}
              label="N/A"
              checked={isNotApplicable}
              disabled={handleInputDisable(
                idProp,
                state,
                disabledInput,
                userId,
              )}
              icon={
                <SvgIcon
                  component={CheckBoxOutlineBlank}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
              checkedIcon={
                <SvgIcon
                  component={CheckBox}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
            />
          }
          classes={{
            root: "attach-form-control-root",
            label: "attach-btn-file-label",
          }}
          labelPlacement="end"
        />
        <Tooltip
          id="attach-tooltip"
          classes={{
            tooltip: "attach-file-tooltip",
            popper: "attach-popper",
            arrow: "attach-arrow",
            tooltipPlacementRight: "attach-right",
          }}
          title={Messages[`${idProp}Message`]}
          placement="right"
          arrow
          open={open}
        >
          <InfoCircle
            className="text-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          />
        </Tooltip>
      </RadioGroup>
    </div>
  );
};

export default AttachFileField;
