import { MapControl } from "@vis.gl/react-google-maps";
import React from "react";
import AutoCompletePlaces from "./AutoComplete";

// CustomMapControl component for the map control
const CustomMapControl = ({ slide6, controlPosition, onPlaceSelect }) => {
  // Adds the autocomplete control to the map
  return (
    <MapControl position={controlPosition}>
      <div className="autocomplete-control">
        <AutoCompletePlaces onPlaceSelect={onPlaceSelect} slide6={slide6} />
      </div>
    </MapControl>
  );
};

export default CustomMapControl;
