import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";

// Function to update the requestStatus of a form in the database
const setFormStatus = async (clientId, projectId, status, userId) => {
  const data = {
    requestStatus: status,
    userId: userId,
  };
  const response = await axios.patch(
    `/api/update-form-status/${clientId}/${projectId}/`,
    data,
    {
      withCredentials: true,
    },
  );

  return response.data;
};

// Custom hook to update the requestStatus of a form in the database
const useSetFormStatus = (
  clientId,
  projectId,
  setRequestStatus,
  setActiveUserId,
) => {
  const { queryClient } = useContext(DashboardContext);
  const setStatus = useMutation({
    mutationFn: (status, userId) =>
      setFormStatus(clientId, projectId, status, userId),
    onError: (error) => {
      console.error("FORM STATUS ERROR: ", error);
    },
    onSettled: async (data, error) => {
      // Do something after the mutation
      setRequestStatus(data.requestStatus);
      setActiveUserId(data.userId);
      await queryClient.invalidateQueries({
        queryKey: ["currentProject", clientId, projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ["clientRows", clientId],
      });
      // Should refetch admin rows to update the requestStatus (starts at "I" [incomplete] & goes to "IP" [in progress] when a user starts to edit the form, this disables the "edit" button for other users)
      queryClient.invalidateQueries({
        queryKey: ["adminRows"],
      });
    },
  });

  return setStatus;
};

export default useSetFormStatus;
